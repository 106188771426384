
import { defineComponent, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();

    const goToSkill = (skill: string) => {
      router.push({ name: 'Analyse Details', params: { id: skill } });
      //currentSkill.value = skill;
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      //load();
    };

    // Charts
    const all = ref([]);
    const neuentwicklung = ref([]);
    const umsetzung = ref([]);
    const sonstige = ref([]);
    store.dispatch('fetchSkills').then((res) => {
      all.value = res
        .map((skill: any) => {
          skill.percent = Math.ceil((skill.reached / skill.max) * 100);
          return skill;
        })
        .sort((a: any, b: any) => {
          if (a.percent > b.percent) return -1;
          if (a.percent < b.percent) return 1;

          return 0;
        });
      neuentwicklung.value = all.value.filter((skill: any) => {
        return skill.group == 'Neuentwicklung';
      });
      umsetzung.value = all.value.filter((skill: any) => {
        return skill.group == 'Umsetzung';
      });
      sonstige.value = all.value.filter((skill: any) => {
        return skill.group == 'Sonstige';
      });
    });

    const neuentwicklungchart = computed(() => {
      const barchart = {
        options: {
          chart: {
            toolbar: {
              show: false,
            },
            height: 600,
          },
          colors: ['#d26464'],
          tooltip: {
            enabled: false,
          },
          grid: {
            row: {
              colors: ['#fff', '#f2f2f2'],
            },
          },
          plotOptions: {
            bar: {
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            },
          },
          dataLabels: {
            formatter: function(val: any, opt: any) {
              return `${val}%`;
            },
            offsetY: -25,
            style: {
              colors: ['#333'],
            },
          },
          xaxis: {
            categories: [] as Array<string>,
            labels: {
              formatter: function(value: string, opt: any) {
                return value.split(/ [a-z]{0,3} /);
              },
              rotate: -50,
              trim: true,
              style: {
                colors: [],
                fontSize: '10px',
                fontWeight: 400,
                cssClass: 'apexcharts-xaxis-label-text',
              },
            },
          },
        },
        series: [
          {
            data: [] as Array<Number>,
          },
        ],
      };
      neuentwicklung.value.forEach((element: Skill) => {
        barchart.options.xaxis.categories.push(element.name);
        barchart.series[0].data.push(element.percent);
      });

      return barchart;
    });

    const umsetzungchart = computed(() => {
      const barchart = {
        options: {
          chart: {
            toolbar: {
              show: false,
            },
            height: 550,
          },
          colors: ['#6ea0d1'],
          tooltip: {
            enabled: false,
          },
          grid: {
            row: {
              colors: ['#fff', '#f2f2f2'],
            },
          },
          plotOptions: {
            bar: {
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            },
          },
          dataLabels: {
            formatter: function(val: any, opt: any) {
              return `${val}%`;
            },
            offsetY: -25,
            style: {
              colors: ['#333'],
            },
          },
          xaxis: {
            categories: [] as Array<string>,
            labels: {
              formatter: function(value: string, opt: any) {
                return value.split(/ [a-z]{0,3} /);
              },
              rotate: -50,
              trim: true,
              style: {
                colors: [],
                fontSize: '10px',
                fontWeight: 400,
                cssClass: 'apexcharts-xaxis-label-text',
              },
            },
          },
        },
        series: [
          {
            data: [] as Array<Number>,
          },
        ],
      };
      umsetzung.value.forEach((element: Skill) => {
        barchart.options.xaxis.categories.push(element.name);
        barchart.series[0].data.push(element.percent);
      });

      return barchart;
    });

    const sonstigechart = computed(() => {
      const barchart = {
        options: {
          chart: {
            toolbar: {
              show: false,
            },
            height: 550,
          },
          colors: ['#3c97a0'],
          tooltip: {
            enabled: false,
          },
          grid: {
            row: {
              colors: ['#fff', '#f2f2f2'],
            },
          },
          plotOptions: {
            bar: {
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            },
          },
          dataLabels: {
            formatter: function(val: any, opt: any) {
              return `${val}%`;
            },
            offsetY: -25,
            style: {
              colors: ['#333'],
            },
          },
          xaxis: {
            categories: [] as Array<string>,
            labels: {
              formatter: function(value: string, opt: any) {
                return value.split(/ [a-z]{0,3} /);
              },
              rotate: -50,
              trim: true,
              style: {
                colors: [],
                fontSize: '10px',
                fontWeight: 400,
                cssClass: 'apexcharts-xaxis-label-text',
              },
            },
          },
        },
        series: [
          {
            data: [] as Array<Number>,
          },
        ],
      };
      sonstige.value.forEach((element: Skill) => {
        barchart.options.xaxis.categories.push(element.name);
        barchart.series[0].data.push(element.percent);
      });

      return barchart;
    });

    return {
      neuentwicklungchart,
      umsetzungchart,
      sonstigechart,
      neuentwicklung,
      umsetzung,
      sonstige,
      goToSkill,
    };
  },
});

<style lang="scss">
#analyse {
  h1 {
    margin-bottom:40px;
  }
  h2 {
    font-size:20px;
    letter-spacing: 1px;
    font-family: 'HelveticaNeueLTPro-Md';
  }
  .divider {
 
    width:100%;
    
    border-top:1px dashed #e0e3eb;
    margin-top:75px;
    margin-bottom:100px;
  }
  .headline-h2 {
    margin-top: 60px;
  }
  .bar-chart {
    .vue-apexcharts {
    }
  }
  .skills-liste-overview {
    // display: block;
    padding-left: 40px;
    .skills-liste-overview-inner {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
    @media (max-width: 1200px) {
      padding-left: 0;
      margin-top: 40px;
      margin-bottom: 40px;
      width: 100%;
    }

    .skill-liste-single {
      padding: 10px;
      background: #f9f9f9;
      border: 1px solid #fff;
      display: inline-block;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
      transition: all 0.2s;
      cursor: pointer;
      @media (max-width: 600px) {
        display: block;
        width: 100%;
      }
      @media (max-width: 500px) {
        text-align: center;
      }
      .nr {
        font-family: 'HelveticaNeueLTPro-Bd';
        font-size: 110%;
        display: inline-block;
        @media (max-width: 500px) {
          display: block;
        }
      }
      .title {
        margin-left: 15px;
        position: relative;
        top: -2px;
        font-size:90%;
        display: inline-block;
        @media (max-width: 500px) {
          display: block;
          margin-left: 0;
        }
      }
      &:hover,
      &.active {
        background: $c-neuentwicklung;
        color: #fff;
      }
    }
  }
  .skills-blue {
    .skill-liste-single {
      &:hover,
      &.active {
        background: $c-umsetzung;
        color: #fff;
      }
    }
  }
  .skills-yellow {
    .skill-liste-single {
      &:hover,
      &.active {
        background: $c-sonstige;
        color: #fff;
      }
    }
  }
}
</style>

<template>
  <div id="analyse">
    <div class="row headline">
      <h1 class="col-12">ANALYSE</h1>
    </div>

    <h2>Neuentwicklung</h2>
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-7 col-md-12 col-sm-12 col-xs-12 bar-chart">
          <apexchart width="100%" type="bar" :options="neuentwicklungchart.options" :series="neuentwicklungchart.series" />
        </div>
        <div class="skills-liste-overview col-lg-5 col-md-12 col-sm-12 ">
          <div class="skills-liste-overview-inner row">
            <div class="skill-liste-single col-lg-6 col-md-6 col-sm-12 " @click="goToSkill(skill['_id'])" :key="index" v-for="(skill, index) in neuentwicklung">
              <div class="txt">
                <span class="nr">{{ skill.percent }}%</span>
                <span class="title">{{ skill.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <h2>Umsetzung</h2>
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-7 col-md-12 col-sm-12 col-xs-12 bar-chart">
          <apexchart width="100%" type="bar" :options="umsetzungchart.options" :series="umsetzungchart.series" />
        </div>
        <div class="skills-liste-overview col-lg-5 col-md-12 col-sm-12 skills-blue">
          
          <div class="skills-liste-overview-inner row">
            <div class="skill-liste-single col-lg-6 col-md-6 col-sm-12" @click="goToSkill(skill['_id'])" :key="index" v-for="(skill, index) in umsetzung">
              <div class="txt">
                <span class="nr">{{ skill.percent }}%</span>
                <span class="title">{{ skill.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <h2>Sonstige</h2>
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-7 col-md-12 col-sm-12 col-xs-12 bar-chart">
          <apexchart width="100%" type="bar" :options="sonstigechart.options" :series="sonstigechart.series" />
        </div>
        <div class="skills-liste-overview col-lg-5 col-md-12 col-sm-12 skills-yellow">
          <div class="skills-liste-overview-inner row">
            <div class="skill-liste-single col-lg-6 col-md-6 col-sm-12" @click="goToSkill(skill['_id'])" :key="index" v-for="(skill, index) in sonstige">
              <div class="txt">
                <span class="nr">{{ skill.percent }}%</span>
                <span class="title">{{ skill.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();

    const goToSkill = (skill: string) => {
      router.push({ name: 'Analyse Details', params: { id: skill } });
      //currentSkill.value = skill;
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      //load();
    };

    // Charts
    const all = ref([]);
    const neuentwicklung = ref([]);
    const umsetzung = ref([]);
    const sonstige = ref([]);
    store.dispatch('fetchSkills').then((res) => {
      all.value = res
        .map((skill: any) => {
          skill.percent = Math.ceil((skill.reached / skill.max) * 100);
          return skill;
        })
        .sort((a: any, b: any) => {
          if (a.percent > b.percent) return -1;
          if (a.percent < b.percent) return 1;

          return 0;
        });
      neuentwicklung.value = all.value.filter((skill: any) => {
        return skill.group == 'Neuentwicklung';
      });
      umsetzung.value = all.value.filter((skill: any) => {
        return skill.group == 'Umsetzung';
      });
      sonstige.value = all.value.filter((skill: any) => {
        return skill.group == 'Sonstige';
      });
    });

    const neuentwicklungchart = computed(() => {
      const barchart = {
        options: {
          chart: {
            toolbar: {
              show: false,
            },
            height: 600,
          },
          colors: ['#d26464'],
          tooltip: {
            enabled: false,
          },
          grid: {
            row: {
              colors: ['#fff', '#f2f2f2'],
            },
          },
          plotOptions: {
            bar: {
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            },
          },
          dataLabels: {
            formatter: function(val: any, opt: any) {
              return `${val}%`;
            },
            offsetY: -25,
            style: {
              colors: ['#333'],
            },
          },
          xaxis: {
            categories: [] as Array<string>,
            labels: {
              formatter: function(value: string, opt: any) {
                return value.split(/ [a-z]{0,3} /);
              },
              rotate: -50,
              trim: true,
              style: {
                colors: [],
                fontSize: '10px',
                fontWeight: 400,
                cssClass: 'apexcharts-xaxis-label-text',
              },
            },
          },
        },
        series: [
          {
            data: [] as Array<Number>,
          },
        ],
      };
      neuentwicklung.value.forEach((element: Skill) => {
        barchart.options.xaxis.categories.push(element.name);
        barchart.series[0].data.push(element.percent);
      });

      return barchart;
    });

    const umsetzungchart = computed(() => {
      const barchart = {
        options: {
          chart: {
            toolbar: {
              show: false,
            },
            height: 550,
          },
          colors: ['#6ea0d1'],
          tooltip: {
            enabled: false,
          },
          grid: {
            row: {
              colors: ['#fff', '#f2f2f2'],
            },
          },
          plotOptions: {
            bar: {
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            },
          },
          dataLabels: {
            formatter: function(val: any, opt: any) {
              return `${val}%`;
            },
            offsetY: -25,
            style: {
              colors: ['#333'],
            },
          },
          xaxis: {
            categories: [] as Array<string>,
            labels: {
              formatter: function(value: string, opt: any) {
                return value.split(/ [a-z]{0,3} /);
              },
              rotate: -50,
              trim: true,
              style: {
                colors: [],
                fontSize: '10px',
                fontWeight: 400,
                cssClass: 'apexcharts-xaxis-label-text',
              },
            },
          },
        },
        series: [
          {
            data: [] as Array<Number>,
          },
        ],
      };
      umsetzung.value.forEach((element: Skill) => {
        barchart.options.xaxis.categories.push(element.name);
        barchart.series[0].data.push(element.percent);
      });

      return barchart;
    });

    const sonstigechart = computed(() => {
      const barchart = {
        options: {
          chart: {
            toolbar: {
              show: false,
            },
            height: 550,
          },
          colors: ['#3c97a0'],
          tooltip: {
            enabled: false,
          },
          grid: {
            row: {
              colors: ['#fff', '#f2f2f2'],
            },
          },
          plotOptions: {
            bar: {
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            },
          },
          dataLabels: {
            formatter: function(val: any, opt: any) {
              return `${val}%`;
            },
            offsetY: -25,
            style: {
              colors: ['#333'],
            },
          },
          xaxis: {
            categories: [] as Array<string>,
            labels: {
              formatter: function(value: string, opt: any) {
                return value.split(/ [a-z]{0,3} /);
              },
              rotate: -50,
              trim: true,
              style: {
                colors: [],
                fontSize: '10px',
                fontWeight: 400,
                cssClass: 'apexcharts-xaxis-label-text',
              },
            },
          },
        },
        series: [
          {
            data: [] as Array<Number>,
          },
        ],
      };
      sonstige.value.forEach((element: Skill) => {
        barchart.options.xaxis.categories.push(element.name);
        barchart.series[0].data.push(element.percent);
      });

      return barchart;
    });

    return {
      neuentwicklungchart,
      umsetzungchart,
      sonstigechart,
      neuentwicklung,
      umsetzung,
      sonstige,
      goToSkill,
    };
  },
});
</script>
